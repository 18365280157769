
<div class="group2">
   <h5 class="m-auto text-center">Juridictions</h5>

  <div class="Card">
    <div class="CardInner">
    <label></label>
    <div class="container">
      <div class="Icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#0c83eb" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"/><line x1="21" y1="21" x2="16.65" y2="16.65"/></svg>
      </div>
      <div class="InputContainer">
        <input placeholder="libelle..."  />
      </div>
    </div>
   </div>
  </div>
  <div class="scroll-container" #scrollContainer>

    <div class="thecard"  *ngFor="let tribunal of juridictions" [ngClass]="{'selected': isInfSelected(1)}" (click)="changeTribunal(tribunal.tribunal)">
      <div class="chambre">
        <div class="wrapper">
          <div class="banner-image"> </div>

          <h1>{{tribunal.tribunal}}</h1>

         <!-- <i  class="fas fa-regular fa-eye text-center ml-3" style="font-size: 13px;"  (click)="openModalDetailsChambre(chambre,'chambres')" ></i> -->


         </div>
        </div>

      <!-- <nb-card  *ngIf="dataType != 'chambres' && dataType != 'dossiers'   " >

        <div class="container">

        </div>

        <div class="row">
          <nb-card-body class="zbody" (click)="onSelectInfraction(inf)">

            <div class="d-flex justify-content-between text-nowrap">

              <i  [ngClass]="isInfSelected(i) ? 'fas fa-check-circle text-success' : 'fas fa-regular fa-building' "style="font-size: 40px;width: 25%;"></i>

              <div class="d-flex justify-content-between">
                <div class="d-flex flex-column" >
                  <div style="margin: auto;">

                    <h6 class="text-wrap" style="font-size: 9px;">{{ chambre.name }}</h6>
                    <h6 style="font-size: 5px;">{{ chambre.libelle }}</h6>
                  </div>
                </div>


                <div class="float-right">
                  <i  class="fas fa-regular fa-eye m-t-3" (click)="openModalDetailsChambre(chambre,'chambres')" ></i>
                </div>
              </div>
            </div>

          </nb-card-body>

        </div>
      </nb-card> -->

    </div>


  </div>
  <div style="margin: auto;">
    <pagination-controls  (pageChange)="pageDossier = $event" previousLabel="Précédent" nextLabel="Suivant"
                          responsive="true" [id]="'dossier'"></pagination-controls>
  </div>

</div>





