import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {FrapperService} from "./frapper.service";
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AssignedService {

  constructor(private http: HttpClient, private frapper: FrapperService,private authservice: AuthService) {}


  async doAssignation(doctype, docname, users) {
    try {
      let me = localStorage.getItem('me')
    // users must be like ["anneibou1@gmail.com"]
    const body= {
      "assign_to_me": 0,
      "assign_to": users, // ["anneibou1@gmail.com"]
      "doctype": doctype,
      "name": docname
    }
    console.log('body ==> ',body)
    let assignations = await this.frapper.CallCustomAPI('frappe.desk.form.assign_to.add',body).toPromise()
    return assignations
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }

  async getAssignedUser(doctype, docname) {
    try {
      let me = localStorage.getItem('me')
      // users must be like ["anneibou1@gmail.com"]
      let doc_assigned_user = await this.frapper.GetDoctypes(doctype,'["_assign","name"]','[ ["name" ,"=", "'+ docname+'"] ]').toPromise()
      return doc_assigned_user
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }

  async getAssignedAnOwnerUser(doctype, docname) {
    try {
      let body = {
        "doctype": doctype,
        "doc_name": docname,
      }
      let doc_assigned_user = await this.frapper.CallCustomAPI('casier_judiciaire.casier_judiciaire.doctype.courrier.courrier.get_user_assign_to_doctype',body).toPromise()
       return doc_assigned_user
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }

}
