<div class="header-container">
  <div class="logo-container">
    <!-- <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a> -->
    <img class="logoimg" (click)="navigateHome()" src="../../../../assets/images/ejustice.png" alt="">
    <a class="logo" href="#" ></a>
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>

<div class="header-container">
  <!-- <nb-actions size="small">

      <nb-action>
        <app-lang-dropdown></app-lang-dropdown>
      </nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user [nbContextMenu]="userMenu" nbContextMenuTag="my-context-menu" [onlyPicture]="userPictureOnly" [name]="user?.username" [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions> -->

<ng-container *ngIf="!is_public;else other">
  <div class="second d-flex">
    <span style="font-size: 15px;color: #102770;margin-right: 10px;margin-top: 8%;">{{profil}}</span>

    <div class="sec-center">
      <input class="dropdown" type="checkbox" id="dropdown" name="dropdown"/>
      <label class="for-dropdown d-flex " for="dropdown"><i class="fas fa-regular fa-user" style="margin-right: 10px;"></i> <i class="fas fa-solid fa-caret-down" ></i></label>

      <div class="section-dropdown d-flex flex-column text-nowrap">
        <div class="d-flex justify-content-between" (click)="openModalProfile()">
          <input class="dropdown-sub" type="checkbox" id="dropdown-profile" name="dropdown-profile"  />
          <span style="font-size: 10px; cursor: pointer;"  class="text-light"><i class="fas fa-regular fa-user" style="font-size: 10px; left: 0; "  ></i>  {{profil}}</span>
        </div>
        <div class="d-flex justify-content-between" (click)="openModalJuridiction()">
          <input class="dropdown-sub" type="checkbox" id="dropdown-profile" name="dropdown-profile"  />
          <span style="font-size: 10px; cursor: pointer;"  class="text-light"><i class="fas fa-regular fa-user" style="font-size: 10px; left: 0; "  ></i> Mes Juridictions</span>
        </div>
        <div class="d-flex justify-content-between" (click)="logout()" >
          <input class="dropdown-sub" type="checkbox" id="dropdown-profile" name="dropdown-profile" />
          <span style="font-size: 11px; cursor: pointer;"  class="text-light"><i class="fas fa-solid fa-lock" style="font-size: 10px; cursor: pointer;"  ></i> Se déconnecter </span>
        </div>
    </div>
    </div>
  </div>

</ng-container>
<ng-template #other>
  <div class="secondmenu">
    <div class="frame">
      <input type="checkbox" id="check" hidden>

      <label for="check">
        <i class="fas fa-bars" id="btn"></i>
        <i class="fas fa-times" id="cancel"></i>
      </label>

      <div class="sidebar">
        <header>Menu</header>
        <a

          class="menu-item {{item.class}}"
          *ngFor="let item of menu"
          [routerLink]="[item.route]"
          [ngClass]="{'active': item.route === activeRoute}"
          (click)="onChangeMenu(item.route)">
          <b><i class="fas fa-solid fa-angle-right" *ngIf="item.class != 'groupTitle'"></i></b>
          <span>{{item.name}}</span>
        </a>

        <a class="menu-item" style="background-color: red;" (click)="logout()">
          <b><i class="fas fa-solid fa-times-circle  "></i></b>
          <span>DECONNEXION</span>
        </a>
      </div>

    </div>
  </div>

</ng-template>




