import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {FrapperService} from "./frapper.service";
import {AssignedService} from "./assigned.service";
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class EnrollementService {

  Doctype_enrolement ='E-Role'
  Doctype_roleGeenral ='RoleGeneral'
  Doctype_chambres ='Chambres'
  Doctype_infractions ='Infractions'

  constructor(private http: HttpClient, private frapper: FrapperService,private authservice: AuthService, private assigneService:AssignedService) {}


  async getnumeroRole() {
    try {
      let me = localStorage.getItem('me')
      const body= {}
      //console.log('body ==> ',body)
      let role = await this.frapper.CallCustomAPI('casier_judiciaire.casier_judiciaire.doctype.courrier.courrier.get_numero_role_general',body).toPromise()
      return role
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }



 // Roles
  async getAllMyRole(filter =null) {
    try {
      let my_roles = await this.frapper.GetDoctypes(this.Doctype_enrolement,'["*"]',filter).toPromise()
      console.log('resp api roles ===> ', my_roles)
      return my_roles
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }

  async getAllRoleGeneral(filter =null) {
    try {
      let my_roles = await this.frapper.GetDoctypes(this.Doctype_roleGeenral,'["*"]',filter).toPromise()
      // console.log('hjnk===> ', my_roles)
      return my_roles
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }

  async getAllMyChambre(filter =null) {
    try {
      let my_chambres = await this.frapper.GetDoctypes(this.Doctype_chambres,'["*"]',filter).toPromise()
      //console.log('My Courriers Principale ===> ', my_courriers)
      return my_chambres
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }
  async getOneChambre(name) {
    try {
      let my_chambres = await this.frapper.GetDoctype(this.Doctype_chambres,name,'["*"]',null).toPromise()
    //console.log('My Courriers Principale ===> ', my_courriers)
    return my_chambres
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }

  async getAllInfractions(filter =null) {
    try {
      let my_chambres = await this.frapper.GetDoctypes(this.Doctype_infractions,'["*"]',filter).toPromise()
    //console.log('My Courriers Principale ===> ', my_courriers)
    return my_chambres
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }

  async getOneRole(roleName) {
    try {
      let my_role = await this.frapper.GetDoctype(this.Doctype_enrolement,roleName,'["*"]',null).toPromise()
    //console.log('Information Of The Courriers ===> ', my_courrier)
    return my_role
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }
  async getOneOfRoleGeneral(roleName) {
    try {
      let my_role = await this.frapper.GetDoctype(this.Doctype_roleGeenral,roleName,'["*"]',null).toPromise()
    //console.log('Information Of The Courriers ===> ', my_courrier)
    return my_role
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }


  async nouveauRole(body) {
    try {
      let new_role = await this.frapper.CreateDoctype(this.Doctype_enrolement,body).toPromise()
      return new_role
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }
  async addInRoleGeneral(body) {
    try {
      let new_role = await this.frapper.CreateDoctype(this.Doctype_roleGeenral,body).toPromise()
    return new_role
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }


  async updateRole(docname,body){
    try {
      let result = this.frapper.UpdateDoctype(this.Doctype_enrolement,docname,body).toPromise()
      return result
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }
  async updateRoleGeneral(docname,body){
    try {
      let result = this.frapper.UpdateDoctype(this.Doctype_roleGeenral,docname,body).toPromise()
      return result
    } catch (error) {
      this.authservice.handleError(error)
    }

  }


  async updateSatate(docname,new_state){
    try {
      let body= {
        "workflow_state": new_state
      }
    let result = this.frapper.UpdateDoctype(this.Doctype_enrolement,docname,body).toPromise()
    return result
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }

  async assignRoleToUser(body){
    try {
      let assignation = await this.assigneService.doAssignation(this.Doctype_enrolement,body.name,body.user)
      return assignation
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }

 // ** Dossier
}
