import {Injectable, OnInit} from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertesService implements OnInit{

  constructor() { }

  ngOnInit(): void {
  }

  Alert(message: 'Warning') {
    Swal.fire(message);
  }
  successNotification(title='Good',body='Merci !') {
    Swal.fire(title, body, 'success');
  }
  errorNotification(title='Erreur',body='Merci !') {
    Swal.fire(title, body, 'error');
  }
  warningNotification(title='Erreur',body='Merci !') {
    Swal.fire(title, body, 'warning');
  }

  alertPaiementConfirmation(body:any) {
    Swal.fire({
      title: 'Paiement',
      text: 'Vous etes sur le point d\'effectuer un paiement de '+body.amount+' avec le '+body.phone_number,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, envoyer',
      cancelButtonText: 'No, annuler',
    }).then((result) => {
      if (result.value) {
        Swal.fire('Envoye!', 'Merci de confirmer l\'operations.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //Swal.fire('Cancelled', 'Product still in our database.)', 'error');
      }
    });
  }

  alertConfirmation() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This process is irreversible.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, go ahead.',
      cancelButtonText: 'No, let me think',
    }).then((result) => {
      if (result.value) {
        Swal.fire('Removed!', 'Product removed successfully.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Product still in our database.)', 'error');
      }
    });
  }


}
