import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {FrapperService} from "./frapper.service";
import {AssignedService} from "./assigned.service";
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DossierService {

  Doctype_dossier ='Dossier'
  Doctype_requisitoire_ordonances ='RequisitoiresOrdonances'

  constructor(private http: HttpClient, private frapper: FrapperService,private authservice: AuthService, private assigneService:AssignedService) {}

 // Dossier
  async getAllMyDossiers(filter =null) {
    try {
      let my_dossier = await this.frapper.GetDoctypes(this.Doctype_dossier,'["*"]',filter).toPromise()
      //console.log('My Courriers Principale ===> ', my_courriers)
      return my_dossier
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }

  async getOneDossier(dossierName) {
    try {
      let my_dossier = await this.frapper.GetDoctype(this.Doctype_dossier,dossierName,'["*"]',null).toPromise()
      //console.log('Information Of The Courriers ===> ', my_courrier)
      return my_dossier
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }

  async nouveauDossier(body) {
    try {
      let new_dossier = await this.frapper.CreateDoctype(this.Doctype_dossier,body).toPromise()
      return new_dossier
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }

  async updateSatate(docname,new_state){
    try {
      let body= {
        "workflow_state": new_state
      }
    let result = this.frapper.UpdateDoctype(this.Doctype_dossier,docname,body).toPromise()
    return result
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }
  async updateDossier(docname,body){
    try {
      let result = this.frapper.UpdateDoctype(this.Doctype_dossier,docname,body).toPromise()
    return result
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }

  async getnumeroDossier() {
    try {
      let me = localStorage.getItem('me')
      const body= {utilisateur:JSON.parse(me).name}
      console.log('body ==> ',body)
      let courrier = await this.frapper.CallCustomAPI('casier_judiciaire.casier_judiciaire.doctype.courrier.courrier.get_my_last_courrier',body).toPromise()
      return courrier
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }

  async assignCourrierToSubstitut(body){
    try {
      let assignation = await this.assigneService.doAssignation(this.Doctype_dossier,body.name,body.user)
      return assignation
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }

  async createRequisitoireOrOrdonance(body){
    try {
      let new_reqOrd = await this.frapper.CreateDoctype(this.Doctype_requisitoire_ordonances,body).toPromise()
    return new_reqOrd
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }

  async getAllMyRequisitoireOrOrdonance(filter =null) {
    try {
      let my_dossier = await this.frapper.GetDoctypes(this.Doctype_requisitoire_ordonances,'["*"]',filter).toPromise()
    //console.log('My Courriers Principale ===> ', my_courriers)
    return my_dossier
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }


 // ** Dossier
}
