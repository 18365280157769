import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {FrapperService} from "./frapper.service";
import {AssignedService} from "./assigned.service";
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RdvService {

  Doctype_rdv ='Rendez vous'

  constructor(private http: HttpClient,private authservice: AuthService, private frapper: FrapperService, private assigneService:AssignedService) {}

 // Jugements

    async getAllRDV(filter=null) {
      try {
        let my_rdvs = await this.frapper.GetDoctypes(this.Doctype_rdv,'["*"]',filter).toPromise()
      
      //console.log('My Courriers Principale ===> ', my_courriers)
      return my_rdvs
      } catch (error) {
        this.authservice.handleError(error)
      }
      
    }

  async getOneRDV(rdvName) {
    try {
      let my_rdv = await this.frapper.GetDoctype(this.Doctype_rdv,rdvName,'["*"]',null).toPromise()
      //console.log('Information Of The Courriers ===> ', my_courrier)
      return my_rdv
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }

  async nouveauRdv(body) {
    try {
      let my_rdv = await this.frapper.CreateDoctype(this.Doctype_rdv,body).toPromise()
      return my_rdv 
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }

  async updateSatate(docname,new_state){
    try {
      let body= {
        "workflow_state": new_state
      }
      let result = this.frapper.UpdateDoctype(this.Doctype_rdv,docname,body).toPromise()
      return result
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }

  async updateRDV(docname,body){
    try {
      let result = this.frapper.UpdateDoctype(this.Doctype_rdv,docname,body).toPromise()
      return result
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }

  async assignRoleToUser(body){
    try {
      let assignation = await this.assigneService.doAssignation(this.Doctype_rdv,body.name,body.user)
      return assignation
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }

 // ** Jugments
}
