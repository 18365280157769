<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0">
  <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/font-awesome/3.1.0/css/font-awesome.min.css" />
  </head>

  <body>
    <div class="page view-details-courrier">
       <h1 style="font-size: 25px;">Informations du fiche {{data.name}} </h1>
       <!-- tabs -->
       <div class="pcss3t pcss3t-effect-scale pcss3t-theme-1">
         <!-- <input type="radio" name="pcss3t" checked  id="tab1"class="tab-content-first">
         <label for="tab1">Elements du dossier</label>

         <input type="radio" name="pcss3t"   id="tab2"class="tab-content-2">

         <label for="tab2">identification parties prenantes</label> -->
         <input type="radio" name="pcss3t" checked   id="tab1"class="tab-content-first">

         <label for="tab1">Jugement</label>

         <input type="radio" name="pcss3t" id="tab2" class="tab-content-2">
         <label for="tab2">Informations pérsonnelles</label>

         <input type="radio" name="pcss3t" id="tab3" class="tab-content-3">
         <label for="tab3"><i ></i>Décision</label>
         <input type="radio" name="pcss3t" id="tab4" class="tab-content-4">
         <label for="tab4"><i ></i>Piéces d'éxecutions</label>

         <ul  style="height: 450px;">
           <li class="tab-content tab-content-first typography" style="overflow: auto;">
            <br>


            <fieldset><br><br>

              <div class="form-card " >
                <div class="d-flex   justify-content-between m-auto text-center" >
                  <div >
                    <label class="fieldlabels">Numéro jugement:  </label>
                      <input class="form-control" type="text" value="{{data.numero_du_jugement}}"  name="numero_jugement" />
                  </div>&nbsp;&nbsp;
                  <div  >
                    <label class="fieldlabels">Date jugement:  </label>
                      <input class="form-control" type="text" value="{{getFormattedDate(data.date_du_jugement)}}"  name="datejugement" />

                  </div>
                  <div (click)="openModalVisualDoc(data.jugement_file)">
                    <label class="custom-file-upload" for="file2">
                      <div class="icon">
                        <i class="fas fa-regular fa-file"></i>
                      </div>
                      <div class="text text-nowrap">
                        <span class="file-label">Voir Fichier Join</span>
                      </div>

                    </label>
                  </div>

                  </div>&nbsp;&nbsp;
            <div class="d-flex justify-content-between">
              <div class="col d-flex  flex-column justify-content-between " >
                <div class="container">
                  <div class="row">
                    <div class="col ">
                      <div class="bg-dark text-light text-center">Liste des réglements</div>

                      <table id="myTable" class="table text-center ">
                        <thead style="font-size: 12px;" class="bg-secondary text-light">
                          <tr>
                            <th>Réglement</th>

                            <th>Date</th>
                            <th>Signifé à</th>
                            <!-- <th>Actions</th> -->
                          </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let reglement of Reglements">
                          <td>
                            <select class="custom-select form-control" id="inputGroupSelect01"  name="reglement" required>
                              <option value="{{reglement.reglement}}">{{reglement.reglement}}</option>
                              <option value="Réputé contradictoire">Réputé contradictoire</option>
                              <option value="Itératif défaut">Itératif défaut</option>
                              <option value="Sur opposition à jugement en date du">Sur opposition à jugement en date du</option>
                              <option value="Contradictoire">Contradictoire</option></select>
                          </td>
                          <td>
                            <input type="text" class="form-control" name="date"  [value]="reglement.date || null"/>
                          </td>
                          <td>
                            <input type="text" class="form-control" name="signifie_a" [value]="reglement.signifie_a || null"/>
                          </td>
                          <!-- <td>
                            <i class="fas fa-solid fa-trash delete-row text-danger mt-2" style="font-size:22px; cursor:pointer"></i>
                          </td> -->
                        </tr>
                          <!-- Lignes  ajoutées ici dynamiquement via le jquery -->
                        </tbody>
                      </table>
                    </div>

                    &nbsp;&nbsp;<div class=" col col-md-4 col-lg-4 text-center" style="margin-left: 50px;">
                      <div class="bg-dark text-light text-center">Liste des infractions</div>
                      <table class="  table  text-center  " style="overflow: auto; font-size: 11px;">

                        <thead class="bg-secondary text-light">
                          <tr>
                            <th scope="col">Libellé</th>

                          </tr>
                        </thead>
                        <tbody>
                          <ng-container>
                            <tr *ngFor="let selectedInfraction of Infractions; let i = index">
                              <td>{{ selectedInfraction.nature}}</td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="selectedInfractions.length > 0; else noData">
                              <tr *ngFor="let selectedInfraction of selectedInfractions; let i = index">

                                <td>{{ selectedInfraction }}</td>
                              </tr>
                            </ng-container>
                            <ng-template #noData>
                              <tr>
                                <td colspan="3"></td>
                              </tr>
                            </ng-template>
                          </tbody>
                      </table>
                    </div>


                  </div>

                </div>

              </div>


            </div>

            </div>
          </fieldset>
           </li>

           <li class="tab-content tab-content-2 typography" style="overflow: auto;height: 450px;">
             <br>
             <fieldset>
              <div class="form-card">
                <div class="row">
                  <div class="col">


                    <label class="fieldlabels">Type d'identification  </label>
                    <input class="form-control" type="text"  name="type_identification" value="{{data.type_didentification}}"/>
                    <label class="fieldlabels">Genre : </label>
                    <input class="form-control" type="text"  name="genre" value="{{data.genre}}"/>
                   <!-- <select class="custom-select" id="inputGroupSelect01"  name="genre" value="{{data.genre}}" required>
                      <option value="Non">Masculin</option>
                      <option value="Oui">Feminin</option>
                    </select>-->

                    <label class="fieldlabels">Nom: </label>
                    <input class="form-control" type="text"  name="nom" value="{{data.nom}}" />

                    <label class="fieldlabels">Prénom: </label>
                    <input class="form-control" type="text"  name="prenom"  value="{{data.prenom}}" />
                    <label class="fieldlabels">Nationnalités : </label>
                    <input class="form-control" type="text"  name="genre" value="{{data['nationalités']}}"/>
                    <!--<select class="custom-select" id="inputGroupSelect01"  name="nationalités"  value="{{data.nationalites}}"  required>
                      <option value="">Sénégalais(e)</option>
                      <option value="">Gambien(ne)</option>
                      <option value="">Malien(ne)</option>
                      <option value="">Guinéen(ne)</option>
                    </select>-->

                    <label class="fieldlabels">Date de naissance: </label>
                    <input class="form-control" type="text"  name="date_de_naissance" value="{{data.date_de_naissance}}" />

                    <label class="fieldlabels">Profession: </label>
                    <input class="form-control" type="text"  name="profession" value="{{data.profession}}" />


                  </div>&nbsp;&nbsp;
                  <div class="col d-flex  flex-column justify-content-between " >
                    <label class="fieldlabels">Numéro d'identification :  </label>
                      <input class="form-control" type="text"  name="numéros_didentification" value="{{data['numéros_didentification']}}"/>

                    <label class="fieldlabels">Pays de résidence: </label>
                    <input class="form-control" type="text"  name="pays_de_residence" value="{{data.residence}}" />

                    <label class="fieldlabels">Résidence: </label>
                    <input class="form-control" type="text"  name="residence" value="{{data.residence}}"/>



                    <label class="fieldlabels">Affiliation: </label>
                    <table class="table table-bordered" >

                      <tbody>
                        <tr>
                          <td><label class="fieldlabels">Nom de la mére: </label>
                            <input class="form-control" type="text"  name="nom_mere" value="{{nom_mere}}" /></td>
                          <td><label class="fieldlabels">Prénom de la mére: </label><input class="form-control" type="text"  name="prenom_mere" value="{{prenom_mere}}"/></td>
                        </tr>

                        <tr>
                          <td><label class="fieldlabels">Nom du pére: </label><input class="form-control" type="text"  name="nom_pere" value="{{nom_pere}}"/></td>
                          <td><label class="fieldlabels">Prénom du pére: </label><input class="form-control" type="text"  name="prenom_pere" value="{{prenom_pere}}"/></td>
                        </tr>

                      </tbody>
                    </table>
                    <label class="fieldlabels">Status matrimonial: </label>
                    <input class="form-control" type="text"  name="status_matrimoniale" value="{{data.status_matrimoniale}}" />
                    <!--<select class="custom-select" id="inputGroupSelect01"  name="status_matrimoniale" value="{{data.status_matrimoniale}}"  required>
                      <option value="">Celibataire</option>
                      <option value="">Marié(e)</option>
                      <option value="">Divorcé(e)</option>
                      <option value="">Veuf (ve)</option>
                    </select>-->

                    <label class="fieldlabels" style="visibility: hidden;">Résidence: </label>
                    <input class="form-control" style="visibility: hidden;" type="text"  name="residence" value="{{data.residence}}"/>

                    <label class="fieldlabels" style="visibility: hidden;">Résidence: </label>
                    <input class="form-control" style="visibility: hidden;" type="text"  name="residence" value="{{data.residence}}"/>

                  </div>
                </div>


              </div>
          </fieldset>
            </li>



          <li class="tab-content tab-content-3 "><br>

           <fieldset>

            <div class="form-card">

              <div class="row">

              </div>

              <div class="row">
                <div class="col">
                   <label class="fieldlabels">Décision judiciaire: </label>
                  <select class="form-select"  (change)="UpdateTypeCondamnation($event.target.value)">
                    <option [value]="Decision_Judiciaire">{{ Decision_Judiciaire }}</option>
                    <option *ngFor="let option of TypeDeCondamnation" [value]="option.name">{{ option.name }}</option>
                  </select>
                   <label class="fieldlabels">Catégorie de la décision: *</label>
                  <select class="form-select"  (change)="UpdateCategoriCondoamnatione($event.target.value)">
                    <option [value]="Categorie_Decision">{{ Categorie_Decision }}</option>
                    <option *ngFor="let option of CategorieDeCondamnation"  [value]="option.name" >{{ option.name }}</option>
                  </select>
                </div> &nbsp; &nbsp;
                <div class="col  " >
                  <label class="fieldlabels">Date de la décision: </label>
                  <input type="text" class="form-control" [value]="data['date_de_la_décision']" name="date_de_la_décision"/>
                </div>
              </div>


              <div class="row">
                <div class="col shadow">
                   <label class="fieldlabels bg-dark text-light">Contrainte par corps:</label>&nbsp;&nbsp;
                   <!-- <i class="fas fa-solid fa-plus text-success" style="font-size: 20px;"  (click)="ajouterLigne()"></i> -->

                  <div class="container">
                    <table class="table  text-center col-md-11 col-lg-11 col-sm-11" style="font-size: 10px;">
                      <thead>
                        <tr>
                          <th >#</th>
                          <th>Début</th>
                          <th>Main levé</th>
                          <th>Fin</th>
                          <th class="col-md-2 col-sm-2" >Action</th> <!-- Colonne pour l'icône d'ajout -->
                        </tr>
                      </thead>
                      <tbody>

                        <tr *ngFor="let cpc of ContrainteParCorps; let i = index">
                          <td class="mt-5"><span >{{i+1}}</span></td>
                          <td><input type="date" [value]="cpc.debut"></td>
                          <td><input type="text" [value]="cpc.main_leve" ></td>
                          <td><input type="date" [value]="cpc.fin" ></td>
                          <td>
                            <i class="fas fa-solid fa-arrow-up text-success mt-2" style="font-size: 17px;"></i></td>
                        </tr>
                        <tr *ngFor="let ligne of lignes; let i = index">
                          <td class="mt-3"><span  [(ngModel)]="ligne.id">{{ligne.id}}</span></td>
                          <td><input type="date" [(ngModel)]="ligne.debut" ></td>
                          <td><input type="date" [(ngModel)]="ligne.fin" ></td>
                          <td><input type="date" [(ngModel)]="ligne.main_leve" ></td>
                          <td>
                            <i class="fas fa-solid fa-trash text-danger mt-2" style="font-size: 17px;"
                               [(ngModel)]="ligne.colonne2" (click)="deleteContrainte(ligne)"></i></td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div> &nbsp; &nbsp;

              </div> <br>


              <div class="d-flex  flex-column col-md-12 col-lg-12">

              <div class="shadow col-md-12 col-lg-12 col-sm-12">
                <label class="fieldlabels  bg-dark text-light">Emprisonnements: </label> &nbsp;&nbsp;
                <!-- <i class="fas fa-solid fa-plus text-success" style="font-size: 20px;"  (click)="ajouterLigne_condamnation()"></i> -->
                <table class="table text-center "  style="font-size: 10px;">
                  <thead>
                    <tr>
                      <th >Type</th>
                      <th>Durée</th>
                      <th>Périodicité</th>
                      <th class="col-md-2 col-sm-2" >Action</th> <!-- Colonne pour l'icône d'ajout -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let emp of Emprisonements">
                      <!-- <td><span [(ngModel)]="ligne.id">{{ligne.id}}</span></td> -->
                      <td><input type="text" [value]="emp.type"></td>
                      <td><input type="text" [value]="emp['durée']"></td>
                      <td><input type="text" [value]="emp['périodicité']"></td>
                      <td>
                        <i class="fas fa-solid fa-arrow-up text-success mt-2" style="font-size: 17px;"></i></td>
                    </tr>
                    <tr *ngFor="let ligne of lignes_condamenations">
                      <!-- <td><span [(ngModel)]="ligne.id">{{ligne.id}}</span></td> -->
                      <td>
                        <select class="form-select" [(ngModel)]="ligne.type" >
                          <option *ngFor="let option of optionsSelect" [value]="option">{{ option }}</option>
                        </select>
                      </td>
                      <td><input type="text" placeholder="Duree"  [(ngModel)]="ligne['durée']" ></td>
                      <td>
                        <select class="form-select" [(ngModel)]="ligne['périodicité']" >
                          <option *ngFor="let option of periodicite" [value]="option">{{ option }}</option>
                        </select>
                      </td>
                      <td> <i class="fas fa-solid fa-trash text-danger mt-2" style="font-size: 17px;"  (click)="deleteEmprisonnement(ligne)" [(ngModel)]="ligne.colonne2"></i></td>

                    </tr>

                  </tbody>
                </table>
              </div>
                &nbsp;&nbsp;
              <div  class="col-md-12 col-lg-12 col-sm-12 shadow">
                <label class="fieldlabels  bg-dark text-light">Amendes: </label> &nbsp;&nbsp;
                <!-- <i class="fas fa-solid fa-plus text-success" (click)="ajouterLigne_ammendements()" style="font-size: 20px;"></i> -->
                <table class="table text-center"  style="font-size: 10px;">
                  <thead>
                    <tr>
                      <th >Montant</th>
                      <th>Aux depends liquidés à la somme</th>
                      <th>Frais de Timbre</th>
                      <th>Frais d'Enregistrement</th>
                      <th>Autres Frais</th>
                      <!-- <th>Periodicite</th> -->
                      <th class="col-md-2 col-sm-2" >Action</th> <!-- Colonne pour l'icône d'ajout -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let amd of Amendes">
                      <!-- <td><span [(ngModel)]="ligne.id">{{ligne.id}}</span></td> -->
                      <td>
                        <input type="number" class="form-control" placeholder="montant" [value]="amd.montant">
                      </td>
                      <td>
                        <input type="text" class="form-control" placeholder="depends liquidés ..." [value]="amd.aux_depens_liquides_a_la_somme">
                      </td>
                      <td>
                        <input type="text" class="form-control" placeholder="Timbre" [value]="amd.timbre" name="timbre">
                      </td>
                      <td>
                        <input type="text" class="form-control" placeholder="Frais enregistrement" [value]="amd.enregistrement" name="enregistrement">
                      </td>
                      <td>
                        <input type="text" class="form-control" placeholder="Autres frais" [value]="amd.autrefrais" name="autrefrais">
                      </td>
                      <td> <i class="fas fa-solid fa-arrow-up text-success mt-2" style="font-size: 17px;"  ></i></td>

                    </tr>
                    <tr *ngFor="let ligne of lignes_ammendes">
                      <!-- <td><span [(ngModel)]="ligne.id">{{ligne.id}}</span></td> -->
                      <td>
                        <input type="number" class="form-control" placeholder="montant" [(ngModel)]="ligne.montant"  name="montant">
                      </td>
                      <td>
                        <input type="text" class="form-control" placeholder="depends liquidés ..." [(ngModel)]="ligne.aux_depens_liquides_a_la_somme" name="aux_depens_liquides_a_la_somme">
                      </td>
                      <td>
                        <input type="text" class="form-control" placeholder="Timbre" [(ngModel)]="ligne.timbre" name="timbre">
                      </td>
                      <td>
                        <input type="text" class="form-control" placeholder="Frais enregistrement" [(ngModel)]="ligne.enregistrement" name="enregistrement">
                      </td>
                      <td>
                        <input type="text" class="form-control" placeholder="Autres frais" [(ngModel)]="ligne.autrefrais" name="autrefrais">
                      </td>
                      <td> <i class="fas fa-solid fa-trash text-danger mt-2" style="font-size: 17px;"  (click)="deleteAmmendes(ligne)"[(ngModel)]="ligne.colonne2"></i></td>

                    </tr>

                  </tbody>
                </table>
              </div>
              </div>
          </div>

         </fieldset>

        </li>
        <li class="tab-content tab-content-4 ">


          <div class="card l-bg-blue-dark" (click)="openModalVisualCasier(data)" >
              <div class="card-statistic-3 " style="margin: auto;">
                  <div class="card-icon card-icon-large text-center" ><i class="fas fa-solid fa-print" style="font-size: 100px;"></i></div>
                  <h5>
                    <span style="background-color: rgb(209, 188, 147);margin: auto; " class="badge badge-sm bg-gradient-primary text-center">Cliquez pour visualiser ou imprimer </span>
                  </h5>

              </div>
          </div>

       </li>

         </ul>

       </div>

       <div class="d-flex justify-content-between" *ngIf="ALLOWED_DECISION_FINAL ==true">
        <div class="col-lg-3 col-sm-3 actionBtn mt-3">
          <div class="addButton  ">
            <div class="button b1  " (click)="UpdateFinalStatus('En appel')">
              <i class="fas fa-solid fa-gavel float-left mt-1" style="font-size: 20px;"></i> Décision en appel </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-3  actionBtn mt-3">
          <div class="addButton  ">
            <div class="button b2  text-light" (click)="UpdateFinalStatus('Definitive')">
              <i class="fas fa-solid fa-gavel float-left mt-1" style="font-size: 20px;"></i> Rendre définitif </div>
          </div>
        </div>
      </div>
     </div>
     <!-- <ngx-ui-loader></ngx-ui-loader> -->
     <ngx-ui-loader></ngx-ui-loader>
  </body>
</html>



