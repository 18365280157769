import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {FrapperService} from "./frapper.service";
import {AssignedService} from "./assigned.service";
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class JugementsService {

  Doctype_jugements ='Jugements'
  Doctype_condamnation_type ='Type de condamnation'
  Doctype_condamnation_categorie ='Categorie de la condamnation'

  Doctype_type_report ='type_jugement_reports'

  constructor(private http: HttpClient, private frapper: FrapperService,private authservice: AuthService, private assigneService:AssignedService) {}

  async getAllTypeRepports(filter=null) {
    try {
      let my_repports = await this.frapper.GetDoctypes(this.Doctype_type_report,'["*"]',filter).toPromise()
      //console.log('My Courriers Principale ===> ', my_courriers)
      return my_repports
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }
 // Jugements

  async getAllMyJugement(filter=null) {
    try {
      let my_jugements = await this.frapper.GetDoctypes(this.Doctype_jugements,'["*"]',filter).toPromise()
      //console.log('My Courriers Principale ===> ', my_courriers)
      return my_jugements
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }

  async getAllCondamnationType(filter=null) {
    try {
      let type_condamnation = await this.frapper.GetDoctypes(this.Doctype_condamnation_type,'["*"]',filter).toPromise()
      //console.log('My Courriers Principale ===> ', my_courriers)
      return type_condamnation
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }

  async getAllCondamnationCategorie(filter=null) {
    try {
      let type_condamnation = await this.frapper.GetDoctypes(this.Doctype_condamnation_categorie,'["*"]',filter).toPromise()
      //console.log('My Courriers Principale ===> ', my_courriers)
      return type_condamnation
    } catch (error) {
      this.authservice.handleError(error)
    }
 
  }

  async getOneJugement(jugementName) {
    try {
      let my_jugement = await this.frapper.GetDoctype(this.Doctype_jugements,jugementName,'["*"]',null).toPromise()
      //console.log('Information Of The Courriers ===> ', my_courrier)
      return my_jugement 
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }

  async nouveauJugement(body) {
    try {
      let new_jugements = await this.frapper.CreateDoctype(this.Doctype_jugements,body).toPromise()
      return new_jugements
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }

  async updateSatate(docname,new_state){
    try {
      let body= {
        "workflow_state": new_state
      }
    let result = this.frapper.UpdateDoctype(this.Doctype_jugements,docname,body).toPromise()
    return result
    } catch (error) {
      this.authservice.handleError(error)
    }
   
  }

  async updateJugement(docname,body){
    try {
      let result = this.frapper.UpdateDoctype(this.Doctype_jugements,docname,body).toPromise()
    return result
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }

  async assignRoleToUser(body){
    try {
      let assignation = await this.assigneService.doAssignation(this.Doctype_jugements,body.name,body.user)
      return assignation
    } catch (error) {
      this.authservice.handleError(error)
    }
    
  }

 // ** Jugments
}
