import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
// import { AuthserviceService } from '../../services/auth/authservice.service';
// import { Permission } from '../../models/permission/permission';
// import { PermissionService } from '../../services/permission/permission.service';
import { AuthService } from '../../services/auth/auth.service';
import { User } from '../../models/user';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {FrapperService} from "../../services/frapper/frapper.service";
import {MyCONST} from "../../CONST/myConst";
import {PublicService} from "../../services/public/public.service";
import {AlertesService} from "../../services/public/alertes.service";
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import {CustomService} from "../../services/frapper/custom.service";
// import { FormControl, FormGroup, FormsModule,ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'ngx-login-signin',
  templateUrl: './login-signin.component.html',
  styleUrls: ['./login-signin.component.scss']
})
export class LoginSigninComponent implements OnInit {

  loginForm: FormGroup | any;
  registerForm: FormGroup | any;
  registerFormControl: any;

  singup: FormGroup | any;
  signuser: any;
  lottieOptions: object;
  lottieStyles: Partial<CSSStyleDeclaration>;
  modalRef!: BsModalRef;

  IS_PUBLIC_FRONT:any =MyCONST.IS_PUBLIC;
  signIn: boolean;


  constructor(private _http: HttpClient, private frapper: FrapperService ,
              private _route: Router, private authService: AuthService,
              private formBuilder: FormBuilder,
              private router: Router,
              private penalService: PenalCourrierService,
              private customService:CustomService,
              private publicService: PublicService, private alerte:AlertesService,
              private modalService: BsModalService,private ngxService: NgxUiLoaderService) {

    this.lottieOptions = {
      path: "../../../assets/lotties/Snow.json", // Chemin vers votre fichier JSON Lottie
      loop: true,
      autoplay: true,
      speed: 0.5 // Add the speed property to the options object
    } as AnimationOptions;

    // Cast the object to the AnimationOptions type
    this.lottieStyles = {
      width: "100%",
      height: "100%",
      top: "0",
      left: "0"
    };
    this.loginForm = new FormGroup(
      {
      // username: new FormControl('@justice.sn', [Validators.required, Validators.minLength(5)]),
      // password: new FormControl('T2ieeZ2J64yrE3', [Validators.required, Validators.minLength(2)]),
        username: new FormControl('', [Validators.required, Validators.minLength(5)]),
        password: new FormControl('', [Validators.required, Validators.minLength(2)]),
      }
    );

    this.registerFormControl = {
      full_name: new FormControl('', Validators.required),
      pseudo: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      cni_number:new FormControl('', Validators.required),
      phone_number: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      confirm_password: new FormControl('', Validators.required)
    }

    this.registerForm = this.formBuilder.group(this.registerFormControl);
  }
  switchContent(){
    this.signIn = !this.signIn
  }

  ngOnInit(): void {
    localStorage.setItem('profil', null);
    localStorage.setItem('access_key', null);
    localStorage.setItem('me', null);
    localStorage.removeItem('profil');
    localStorage.removeItem('access_key');
    localStorage.removeItem('me');
    localStorage.clear();
  }


  // registration
  register(){
    //console.log('IN REGISTER')
    this.ngxService.start();

    let body = {
      "pseudo": this.registerForm.value.pseudo,
      "full_name": this.registerForm.value.full_name,
      "email":  this.registerForm.value.email,
      "type": "individual",
      "password":  this.registerForm.value.password,
      "password_confirmation": this.registerForm.value.confirm_password,
      "customer_informations":{
        "ext_id": 'web'+ Date.now(),
        "gender":"m",
        "placeOfBirth":"",
        "countryOfBirth":"",
        "nationalities":"",
        "maritalStatus":"",
        "birthDate":"",
        "deathDate":"",
        "title":"",
        "givenName":"",
        "middleName":"",
        "familyName":"",
        "fullName": this.registerForm.value.full_name,
        "formattedName":"",
        "location":"",
        "status":"Busness"
      },
      "form_contact":{
        "country":"",
        "city":"",
        "state_or_province":"",
        "street":"",
        "email_address": this.registerForm.value.email,
        "type":"Personne",
        "number": this.registerForm.value.phone_number,
        "postcode":""
      },
      "customer_indentifications":{
        "cni_number":{
          "value": this.registerForm.value.cni_number,
          "issuing_authority":"Republic of Senegal",
          "issuing_date":"12-06-2017",
          "expiring_date":"11-06-2027"
        }
      }

    }
    console.log({'inscription': body});
    try{
      this.publicService.register(body).subscribe(res => {
        this.router.navigate(['/login']);
        this.ngxService.stop();

        this.alerte.successNotification(this.registerForm.value.full_name+',Votre compte est actif ', 'Bienvenue sur e-justice')
      },error => {
        this.ngxService.stop();
        //console.log('ERRORRRRR ===> ', error)
        try{
          this.alerte.errorNotification('Creation de Compte',error.error.error_message.errors[0].message)
        }catch (e) {
          this.alerte.errorNotification('Erreur lors de la creation du compte de Compte','Merci de revoir les informations')
        }

      })
    }catch (e) {
      this.ngxService.stop();
      //console.log('e ===> ', e)
      this.alerte.errorNotification('Creation de Compte','Une Erreur est survenue lors de la creation du compte')

    }
  }


   async loginToFrapper(loginForm: FormGroup){
     this.ngxService.start();
    //  this._route.navigate(['E-justice/GREFFIER/role-generale']);
    //  this.ngxService.stop();

     let isUser : User;
     if (this.IS_PUBLIC_FRONT ==0){
       this.frapper.login(loginForm.value.username, loginForm.value.password).subscribe({
         next: async res => {
           //console.log({'suuucccc': res});
           if (res.message.success_key == 0) {
             this.ngxService.stop();
             this.alerte.errorNotification('Connexion','erreur lors de la connexion, veillez verifier les informations saisies')
             return

           }
           if (res.message.success_key == 1) {
            // this.ngxService.stop();
             let access_key = res.message.api_key + ':' + res.message.api_secret
             access_key = btoa(access_key)
             localStorage.setItem('me_username', res.message.username);
             localStorage.setItem('access_key', access_key);
             //localStorage.setItem('full_name', res.full_name);
             await this.customService.getAllMyTribunal().then(res =>{
              //console.log('My tribunal ==> ',res.data)
               let my_info = res.data[0]
                localStorage.setItem('my_tribunal', JSON.stringify(my_info));
               if(my_info){
                 this.customService.getTribunal(my_info.name).then(res2 =>{
                   //console.log('My tribunal MORE ==> ',res2.data)
                   let more_info = res2.data
                   localStorage.setItem('my_juridictions', JSON.stringify(more_info));
                 },err=>{
                   console.log('Tribuanl More detail Error ==> ',err)
                 })
               }


             },err=>{
               console.log('Tribuanl Error ==> ',err)
             })

             let me_informations= await this.frapper.GetUsersInformations(res.message.email).toPromise()
             //console.log(me_informations.data)
             localStorage.setItem('me', JSON.stringify(me_informations.data));
             //console.log('access_key ====> ',access_key)
           }


           let profile = await this.getProfile(loginForm.value.username);

           const first_route = profile[0]
           //console.log('XXX === ', profile)
           localStorage.setItem('profil', first_route.role);
           //console.log({'defaul path': first_route.primay_route});
           //this.ngxService.stop();
           this._route.navigate(['E-justice']);

           // this._route.navigate(['E-justice']);
           // this.alertService.success('Connected');
           // this.router.navigate(['home']);
         },
         error: err => {
           console.log({'errrrrropop': err});
           this.alerte.errorNotification('Connexion','erreur lors de la connexion, veillez verifier les informations saisies')
           // this.alertService.danger('Connection rejected, please try again');
           setTimeout(() => {
             this.ngxService.stop();
             // this.loading = false;
           }, 1000);
         },
         complete: () => {
           // Use setTimeout as a fallback in case the observable does not complete in a timely manner
           setTimeout(() => {
             // this.loading = false;
           }, 3000);
         }
       });
     }
     else {
       //console.log('IN PUBLIC ACCESS ===>')
       try{
         const body = {
           pseudo: this.loginForm.value.username,
           password:this.loginForm.value.password
         }
         this.publicService.login(body).subscribe(res => {
           console.log(res)
           this.alerte.successNotification('Bienvenue', res.me.full_name)
           this._route.navigate(['X']);
           this.ngxService.stop();

         },error => {
           console.log('ERROR ', error)
           this.ngxService.stop();
           this.alerte.errorNotification('Connexion','erreur lors de la connexion, veillez verifier les informations saisies')

         })
       }catch (e) {
         console.log('NO Log',e)
         this.ngxService.stop();
       }
     }
   }

   getProfile(email:string){
    const userData = this.authService.getRolePath(email);
   // console.log({"the infos user": userData});
    return userData;
   }



}
