import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BsModalRef, ModalOptions, BsModalService } from 'ngx-bootstrap/modal';
import { NgxPrintElementService, Config } from 'ngx-print-element';
import { MyCONST } from '../../CONST/myConst';
import { UsercourrierService } from '../../services/frapper/usercourrier.service';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';

import { NgxPrintDirective } from 'ngx-print';
import { DatePipe } from '@angular/common';



@Component({
  selector: 'app-visualize-execution',
  templateUrl: './visualize-execution.component.html',
  styleUrls: ['./visualize-execution.component.scss'],
})
export class VisualizeExecutionComponent {
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  data:any;
  dataAllInformation:any;
  dataPiecesJointes:any  =[];
  type: any;
  courriers: any[] = [];
  internesCourriers: any[] = [];
  internesPrincipals: any[] = [];
  isButtonDisabled: boolean = true;
  verification : String  = MyCONST.DOMAIN
  nom_pere =null
  prenom_pere =null
  nom_mere =null
  prenom_mere =null

  tribunal: string = MyCONST.DETAIL_TRIBUNAL;

  profil: string ;
  @Input() parentModal: BsModalRef;

  @ViewChild('elementToPrint') printElement!:any;


  @ViewChild(NgxPrintDirective) ngxPrint!: NgxPrintDirective;


  constructor(private _formBuilder: FormBuilder,private options: ModalOptions,
              private penalCourrierService: PenalCourrierService,
              private userCourrierService: UsercourrierService,
              public print: NgxPrintElementService,
              private datePipe: DatePipe,
              private modalService: BsModalService) {
    this.data = this.options.initialState.data;
    this.data.contraintes_par_corps=this.data.contraintes_par_corps.length>0?this.data.contraintes_par_corps
        : [{'debut':null,'main_leve':null}]
    this.data.amende = this.data.amende.length>0?this.data.amende
      : [{'aux_depens_liquides_a_la_somme':null,'montant':null}]
    this.data.emprisonnement = this.data.emprisonnement.length>0?this.data.emprisonnement
      : [{'périodicité':null,'durée':null,'type':null}]
    this.data.infractions = this.data.infractions.length>0?this.data.infractions
      : [{'nature':null}]


    this.type = this.options.initialState.type;
    console.log({'data in visual': this.data});
    this.doAffiliation()
    try{
      let tribunal_information:any = JSON.parse(localStorage.getItem('my_tribunal'))
      //console.log('tribunal_information====> ',tribunal_information)
      if(tribunal_information.code){
        //console.log('UPDATE')
        this.tribunal= tribunal_information.detail_tribunal
        // this.tribunal_detail= tribunal_information.detail_tribunal
      }
    }catch (e) {
      console.log('ERROR ON GET TRIBUNAL====> ')
    }
  }

  getTotal(arry_of_vals:any){
    let sum =0
    for (let val of arry_of_vals){
      //console.log('val ===> ',val)
      sum =parseInt(val)+sum
      //console.log('SUM ===> ',sum)
    }
    return sum
  }

  doAffiliation(){
    for(let aff of this.data.affiliations){
      if(aff.lien =='Pere'){
        this.nom_pere = aff.nom
        this.prenom_pere = aff.prenom
      }
      if(aff.lien =='Mere'){
        this.nom_mere = aff.nom
        this.prenom_mere = aff.prenom
      }
    }
  }

  getFormattedDate(date=null): string {
    if(date==null){
      return '...-...-....'
    }
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }




  public config: Config = {
    printMode: 'template', // template-popup
    popupProperties: 'toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,fullscreen=yes',
    pageTitle: 'casier',
    // htmlType: 'text',

    styles: [
      `
      @media print {
        @page {
          size: A4; /* Définit la taille de la page comme A4 */
          margin: 15px; /* Supprime les marges par défaut */
           page-break-before: always;
        }
        
        body {
          background-color: red;
          width: 210mm; /* Largeur de la page A4 en millimètres */
          height: 297mm; /* Hauteur de la page A4 en millimètres */
          margin: 15px; /* Supprime les marges par défaut */
        }
      }
     .header{
        display: flex;
        justify-content: space-between;
        text-align: center;
      }

      .container {
        display: flex; /* Utilisation de flexbox pour aligner les éléments horizontalement */
        align-items: center; /* Centre verticalement les éléments */
        flex-direction: row;
      }
      .trib{
          margin-left: 15px;
          width: 300px;
          font-size: 15px;
      }

      .vl {
        border-left: 6px solid black; /* Style de la ligne verticale */
        height: 180px; /* Hauteur de la ligne verticale */
        margin-right: 15px;

      }

      .vl-renseigne{
        border-left: 6px solid black; /* Style de la ligne verticale */
        height: 180px; /* Hauteur de la ligne verticale */
        margin-top: 15px;
   

      }

      .vl-jugement {
        border-left: 6px solid black; /* Style de la ligne verticale */
        height: 120px; /* Hauteur de la ligne verticale */
        margin-top: 10px;

      }

      .vl-infrac {
        border-left: 6px solid black; /* Style de la ligne verticale */
        height: 120px; /* Hauteur de la ligne verticale */
        margin-top: 10px;
         margin-right: 25px;

      }

      .vl-condamn {
        border-left: 6px solid black; /* Style de la ligne verticale */
        height: 150px; /* Hauteur de la ligne verticale */
        margin-top: 10px;
        margin-right: 25px;

      }

      .vertical-text {
        
        writing-mode: vertical-rl; /* Définit l'écriture verticale de bas en haut */
        transform: rotate(-180deg); /* Utilisé pour IE/Edge pour obtenir une rotation correcte */
        transform-origin: bottom; /* Spécifie le point de rotation au bas du texte */
        white-space: nowrap; /* Empêche le texte de se briser sur plusieurs lignes */
        margin-left: 10px; /* Marge à gauche pour séparer le texte de la ligne verticale */
        margin-right: 10px;
        text-align: center;
        margin-top: -200px;
        margin-left: -70px;

      }

      .vertical-text-renseigne {
        
        writing-mode: vertical-rl; /* Définit l'écriture verticale de bas en haut */
        transform: rotate(-180deg); /* Utilisé pour IE/Edge pour obtenir une rotation correcte */
        transform-origin: bottom; /* Spécifie le point de rotation au bas du texte */
        white-space: nowrap; /* Empêche le texte de se briser sur plusieurs lignes */

        text-align: center;
        margin-top: -240px;
        width: auto;
        
        margin-left: -75px;
        
       
      }

      .vertical-text-jugement {
        
        writing-mode: vertical-rl; /* Définit l'écriture verticale de bas en haut */
        transform: rotate(-180deg); /* Utilisé pour IE/Edge pour obtenir une rotation correcte */
        transform-origin: bottom; /* Spécifie le point de rotation au bas du texte */
        white-space: nowrap; /* Empêche le texte de se briser sur plusieurs lignes */

        text-align: center;
        margin-top: -150px;
        width: auto;
        
        margin-left: -75px;
        position: relative;
      }

      .vertical-text-condamn {
        writing-mode: vertical-rl; 
        transform: rotate(-180deg); 
        transform-origin: bottom; 
        white-space: nowrap;

        text-align: center;
        margin-top: -230px;
        width: auto;
        
      
      margin-left: 220px;


      }

      .vertical-text-juge {
        
        writing-mode: vertical-rl; /* Définit l'écriture verticale de bas en haut */
        transform: rotate(-180deg); /* Utilisé pour IE/Edge pour obtenir une rotation correcte */
        transform-origin: bottom; /* Spécifie le point de rotation au bas du texte */
        white-space: nowrap; /* Empêche le texte de se briser sur plusieurs lignes */

        text-align: center;
        margin-top: -200px;
        
        margin-left: -40px;
        position: relative;
      }

      .vertical-text-second {
        writing-mode: vertical-rl; 
        transform: rotate(-180deg); 
        transform-origin: bottom; 
        white-space: nowrap;

        text-align: center;
        margin-top: -800px;
        width: auto;
        height: auto;
        
      



      }

      .vertical-text-infrac {
        writing-mode: vertical-rl; 
        transform: rotate(-180deg); 
        transform-origin: bottom; 
        white-space: nowrap;

        text-align: center;
        margin-top: -150px;

        margin-left: 220px;


      }
      .table_ecivil{
        margin-top: 0px;
        width: auto;
        min-width: 500px;
        font-size: 12px;
        tbody{
          text-align: start;
          align-items: start;
        }
        tr th{
          text-align: end;
        }

      }
        .table_renseigne{
        margin-top: 20px;
        width: auto;
        min-width: 500px;
        font-size: 12px;
        tr th{
          text-align: end;
        }
           tbody{
          text-align: start;
          align-items: start;
        }

      }

      .table_condamn{
        margin-top: 0px;
         width: auto;
        min-width: 500px;
        font-size: 12px;
        tr th{
          text-align: end;
        }
           tbody{
          text-align: start;
          align-items: start;
        }
      }

      .table_jugement{
        margin-top: 10px;
        width: auto;
        min-width: 500px;
        font-size: 12px;
        tr th{
          text-align: end;
        }
           tbody{
          text-align: start;
          align-items: start;
        }
      }
      .table_infrac{
        margin-top: 20px;
        width: auto;
        min-width: 500px;
        font-size: 12px;
        tr th{
          text-align: end;
        }
           tbody{
          text-align: start;
          align-items: start;
        }
      }

        .block{
        display: flex;
        justify-content: space-start;
      }

      .table_second{
        font-size: 14px;
      }
      `

    ]
  }





  activePane = 0;



  onPrint2(el: any) {
    try {
      this.print.print(el, this.config).subscribe(console.log);

    } catch (error) {
      console.log('dddddd==> '+ error);


    }

  }
}
