import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import { LoginSigninComponent } from './E-justice/authentification/login-signin/login-signin.component';
import { DashboardRoleGeneraleComponent } from './E-justice/Dashboards/GREFFIER/dashboard-role-generale/dashboard-role-generale.component';


export const routes: Routes = [

  {
    path: 'E-justice',
    loadChildren: () => import('./E-justice/allpage/allpage.module')
      .then(m => m.AllpageModule),
  },
  {
    path: 'X',
    loadChildren: () => import('./E-justice/Dashboards/PUBLIC/public.module')
      .then(m => m.PublicModule),
  },
  
  {
    path: '',
    component: LoginSigninComponent,
    // component: DashboardRoleGeneraleComponent,
    // component: NbAuthComponent,
    children: [
      // {
      //    path: 'auth',
      //   component: LoginSigninComponent,
      // },
      {
        path: 'login',
        component: LoginSigninComponent,
      },
      {
        path: 'register',
        component: NbRegisterComponent,
      },
      {
        path: 'logout',
        component: NbLogoutComponent,
      },
      // {
      //   path: 'request-password',
      //   component: NbRequestPasswordComponent,
      // },
      // {
      //   path: 'reset-password',
      //   component: NbResetPasswordComponent,
      // },
    ],
  },
  { path: '', redirectTo: 'login', pathMatch: 'full'},
  { path: '**', redirectTo: 'login'},
];

const config: ExtraOptions = {
  useHash: true,
  onSameUrlNavigation:'reload'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
