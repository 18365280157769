import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NbContextMenuDirective, NbMenuService } from '@nebular/theme';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { AnimationOptions } from 'ngx-lottie';
import { LanguageService } from 'typescript';
import { EnrollementService } from '../../services/frapper/enrollement.service';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { ViewDetailsChambreComponent } from '../view-details-chambre/view-details-chambre.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {CustomService} from "../../services/frapper/custom.service";
import {AlertesService} from "../../services/public/alertes.service";

@Component({
  selector: 'app-view-list-chambre',
  templateUrl: './view-list-juridictions.component.html',
  styleUrls: ["./view-list-juridictions.component.scss"]
})
export class ViewListJuridictionsComponent {

  selectedInfractionIndex: number = -1; // Initialisez à -1 pour indiquer aucun dossier sélectionné

@Input() userIds: string[] = [];
  data: any
  juridictions: any[] = [];
  my_tribunal:any = null
  @ViewChild(NbContextMenuDirective) contextMenu: NbContextMenuDirective;
  constructor(
    private modalservice: BsModalService,
    private nbMenuService: NbMenuService,
    private enrollementService: EnrollementService,
    private alerte:AlertesService,
    private _route: Router,
    private customService:CustomService,
    private ngxService: NgxUiLoaderService,
    private router: Router,
    private penalService: PenalCourrierService,
    private option: ModalOptions,

  ) {
    try{
      this.juridictions =[]
      this.my_tribunal = JSON.parse(localStorage.getItem('my_tribunal'))
      let juridiction = JSON.parse(localStorage.getItem('my_juridictions'))

      this.juridictions = juridiction.table_gppq
      this.juridictions.push({tribunal:juridiction.name})
      this.getAllJuridiction()
      //console.log('tribunal_information====> ',tribunal_information)
    }catch (e) {
      console.log('ERROR ON GET TRIBUNAL====> ')
      this.my_tribunal=null
    }
  }

  searchText: string = ''; // Assurez-vous que searchTerm est initialisé
  selected: string = '';
  pageDossier: number = 1;
  key: string = '';
  modalRef!: BsModalRef;


  @Input() dataType: string = '';
  // items = [
  //   { title: 'Supprimer' },
  //   { title: 'Afficher famille salarié'},
  // ];



  lottieOptions: object;
  lottieStyles: Partial<CSSStyleDeclaration>;
  bsModalRef?: BsModalRef;


  isInfSelected(index: number) {
    return this.selectedInfractionIndex === index;
  }
  toggleSelection(index: number) {
    if (this.selectedInfractionIndex === index) {
      // Si le même dossier est cliqué à nouveau, désélectionnez-le
      this.selectedInfractionIndex = -1;
    } else {
      this.selectedInfractionIndex = index;
    }
  }
  getAllJuridiction() {
    this.ngxService.start();
    //this.juridictions = this.my_tribunal.table_gppq
    console.log(this.juridictions)
    this.ngxService.stop();
  }

  changeTribunal(tribunal_name){
    this.ngxService.start()
    this.customService.getDEtailTribunalNotAllowed(tribunal_name).then(res =>{
      let my_info= res.message[0]
      localStorage.setItem('my_tribunal', JSON.stringify(my_info));
      this._route.navigate(['E-justice']);
      location.reload();
      this.ngxService.stop()
    },err=>{
      this.alerte.errorNotification('Changement de juridiction ','Une erreur est survenue')
      this.ngxService.stop()
    })
  }

// salarie1
  ngOnInit() {
  }

  options: AnimationOptions = {
    path: "./assets/lotties/add.json"
  };
}
